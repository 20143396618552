.team-members-sec {
  background-color: transparent;
  padding: 150px 0;
  // margin-left: -34px;
  .title {
    font-family: "Georgia";
    font-weight: 400;
    font-size: 70px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 52px;
    span {
      font-family: "Georgia";
      text-transform: lowercase;
    }
  }
  .small-dot {
    background: #ea3335;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-left: 18px;
    margin-top: 40px;
    opacity: 0;
  }
  .des {
    width: 592px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.025em;
    color: #292930;
    margin-left: 268px;
  }
}
// .small-dot {
//   animation: bounceOnce 2s linear infinite;
//   animation-duration: 2s;
// }

// @-webkit-keyframes bounceOnce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     -webkit-transform: translateY(0);
//   }
//   40% {
//     -webkit-transform: translateY(-50px);
//   }
//   60% {
//     -webkit-transform: translateY(-10px);
//   }
// }

// @keyframes bounceOnce {
//   0%,
//   20%,
//   50%,
//   80%,
//   100% {
//     transform: translateY(0);
//   }
//   40% {
//     transform: translateY(-50px);
//   }
//   60% {
//     transform: translateY(-10px);
//   }
// }

.dot-bounce-in-down {
  -webkit-animation: bounceInDown 1s ease-in-out;
  animation: bounceInDown 1s ease-in-out;
  opacity: 1 !important;
}

@keyframes bounceInDown {
   0% {
      opacity: 0;
      transform: translateY(-2000px);
   }
   60% {
      opacity: 1;
      transform: translateY(30px);
   }
   80% {
      transform: translateY(-10px);
   }
   100% {
      transform: translateY(0);
   }
} 