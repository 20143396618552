@media (max-width: 700px) {
  .mar-top {
    margin-top: 200px;
    .page-heading {
      font-size: 70px;
    }
    .make-flex {
      display: block;
      .des {
        width: 100%;
        margin-right: 0px;
      }
      .parallax_bg {
        width: 100%;
        height: 300px;
        margin-top: 20px;
        img {
          margin-top: 50px;
        }
        .react-parallax .bg-img {
          height: 300px;
        }
      }
    }
    .info {
      margin-top: 50px;
      margin-bottom: 150px;
      .head {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .des {
        padding-right: 0px;
      }
    }
  }
}
