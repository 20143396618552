.app {
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.mouse-circle {
  position: fixed;
  // transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ea3335;
  pointer-events: none;
}

.bounce-circle {
  width: 80px;
  height: 80px;
  left: 50px;
}

.main-drap {
  font-family: system-ui;
  background: #111;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-wrapper {
  height: 100vh;
}

#smooth-content {
  display: inline-block;
}

// Parallax bg

// Page scroll circle

#scrollCircle {
  transition: 0.25s;
}

@media only screen and (min-width: 961px) {
  .circular-text {
    cursor: pointer;
    position: fixed;
    bottom: 44px;
    left: 61px;
    z-index: 99999;
  }

  .scroll-cirlce {
    width: 70px !important;
    height: 70px !important;
    cursor: pointer;
  }
}

#mytext {
  fill: #ea3335;
  height: auto;
  max-width: 177vmin;
  transform-origin: center;
  width: 163%;
  position: absolute;
  left: -22px;
  top: -22px;
  cursor: pointer;
}

.scroll-cirlce {
  background: #03081b;
  border-radius: 100%;
  cursor: pointer;
  -webkit-mask-image: radial-gradient(
    circle,
    black 100%,
    rgba(0, 0, 0, 5.5) 81%
  );
  mask-image: radial-gradient(circle, black 68%, rgba(0, 0, 0, 0.5) 79%);
}

.progress-bar {
  height: 0px;
  width: 70px;
  transition: height 0.5s;
  z-index: 99999;
  background: #ea3335;
}

.scroll-cirlce img {
  position: absolute;
  top: 35%;
  bottom: 50%;
  transform: rotate(90deg);
  left: 32.5%;
}

.blue-about-bg {
  background: rgb(3, 8, 27);
  margin-bottom: -1px;
}

.transform-bubbles {
  position: fixed;
  top: 20%;
  right: 20px;
}

// Slick slider for team

// .slick-slide {
//   width: auto !important;
// }