.faqs-banner {
  margin-top: 160px;
  .eye-image {
    text-align: right;
    position: relative;
    z-index: 1;
    img {
      width: 770px;
      height: 443px;
    }
  }
  .heading {
    // font-weight: 400;
    // font-size: 160px;
    // line-height: 80%;
    // letter-spacing: -0.025em;
    // text-transform: uppercase;
    // color: #000000;
    // margin-left: -41.5px;
    // margin-top: -9px;
    position: relative;
    z-index: 20;
    // display: inline-block;
    margin-left: 25px;
    label {
      font-family: "Georgia";
      margin-bottom: 0;
    }
    img {
      width: 100%;
      max-width: 1000px;
      margin-left: 20px;
    }
  }
}

.faqs-sec {
  margin-top: 140px;
  margin-bottom: 150px;

  .content {
    .panel-group {
      margin-bottom: 0;

      .panel {
        border-radius: 0;
        box-shadow: none;
        border-bottom: 0.5px solid #0f1426;

        .panel-heading {
          padding: 0;
          h4 {
            margin-bottom: 0;
            a {
              position: relative;
              display: block;
              font-weight: 500;
              font-size: 24px;
              line-height: 140%;
              text-transform: uppercase;
              color: #000000;
              margin-bottom: 2px;
              padding-top: 20px;
              padding-left: 10px;
              padding-bottom: 20px;
              text-decoration: none;
              transition: 0.15s all ease-in-out;
              margin-bottom: 0px;
              span {
                text-transform: lowercase;
                font-family: "Georgia";
              }
              label {
                font-family: "Georgia";
                margin-bottom: 0;
              }

              .faq-arrow {
                width: 8.17px;
                height: 8.17px;
                transition: -webkit-transform 0.8s;
                transition: transform 0.8s;
                transition: transform 0.8s, -webkit-transform 0.8s;
                -webkit-transition-timing-function: cubic-bezier(
                  0.68,
                  -0.55,
                  0.265,
                  1.55
                );
                border-top: 1px solid #202020;
                border-right: 1px solid #202020;
                float: right;
                position: relative;
                top: 5px;
                right: 27px;
                -webkit-transform: rotate(135deg);
                transform: rotate(135deg);
              }
              &:hover,
              &:not(.collapsed) {
                background: #fff;
                transition: 0.15s all ease-in-out;
              }
              &:not(.collapsed) .faq-arrow {
                transition: -webkit-transform 0.8s;
                transition: transform 0.8s;
                transition: transform 0.8s, -webkit-transform 0.8s;
                -webkit-transition-timing-function: cubic-bezier(
                  0.68,
                  -0.55,
                  0.265,
                  1.55
                );
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
              i {
                color: #999;
              }
            }
          }
        }
        &:first-child {
          border-top: 0.5px solid #0f1426;
        }
        .panel-body {
          padding-top: 0;
          p {
            color: #292930;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.025em;
            line-height: 22px;
            margin-bottom: 100px;
            padding-left: 10px;
            width: 95%;
          }
        }
        .panel-heading + .panel-collapse > .list-group,
        .panel-heading + .panel-collapse > .panel-body {
          border-top: none;
        }
      }
    }
  }
}

// image magnatic
.faq-image {
  width: 1000px;
  height: 440px;
  background: url("../../Images/faqs_banner.jpg") left top no-repeat;
  margin-left: -20px;
  margin-top: -5px;
}
.eye-image {
  width: 720px;
  height: 400px;
  margin-bottom: -61px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  float: right;
}

.accordion {
  margin-top: 100px;
  .accordion-item {
    border-bottom: 0.5px solid #0f1426;
    .accordion-header {
      margin-bottom: 0;
      .accordion-button {
        position: relative;
        width: 100%;
        border: none;
        background: transparent;
        text-align: left;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        text-transform: uppercase;
        color: #000000;
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 20px;
        text-decoration: none;
        transition: 0.15s all ease-in-out;
        margin-bottom: 0px;
        position: relative;
        span {
          text-transform: lowercase;
          font-family: "Georgia";
        }
        label {
          font-family: "Georgia";
          margin-bottom: 0;
        }
        .arrow {
          position: absolute;
          right: 15px;
          top: 12px;
          svg {
            transition: -webkit-transform 0.8s;
            transition: transform 0.8s;
            transition: transform 0.8s, -webkit-transform 0.8s;
            -webkit-transition-timing-function: cubic-bezier(
              0.68,
              -0.55,
              0.265,
              1.55
            );
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
          }
        }
      }
      .collapsed {
        .arrow {
          position: absolute;
          right: 15px;
          top: 12px;
          svg {
            transition: -webkit-transform 0.8s;
            transition: transform 0.8s;
            transition: transform 0.8s, -webkit-transform 0.8s;
            -webkit-transition-timing-function: cubic-bezier(
              0.68,
              -0.55,
              0.265,
              1.55
            );
            -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
          }
        }
      }
    }
    .accordion-collapse {
      .accordion-body {
        color: #292930;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.025em;
        line-height: 22px;
        margin-bottom: 100px;
        padding-left: 10px;
        width: 100%;
      }
    }
  }
  &:first-child {
    border-top: 0.5px solid #0f1426;
  }
}
