.creation-home {
  padding-bottom: 100px;
  background: transparent;
  margin-top: -1px;
  .cus-flex {
    display: flex;
    justify-content: flex-end;
  }
  .text {
    height: 420px;
    position: relative;
  }
  .align-basline {
    align-self: last baseline;
    -webkit-align-self: last baseline;
    -ms-flex-item-align: last baseline;
    padding: 0;
  }
  .sec-title {
    font-weight: 400;
    font-size: 80px;
    line-height: 100%;
    text-align: right;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    align-self: last baseline;
    margin: 0 46px;
    position: absolute;
    right: 0;
    bottom: 0;
    a {
      color: #000000;
    }
    span {
      font-family: "Georgia", Sans-serif;
      text-transform: lowercase;
    }
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .bg-sec {
    transition: transform 0.2s;
    .react-parallax {
      transition: transform 0.2s;
      .react-parallax-bgimage {
        transition: transform 0.2s;
      }
      .bg-img{
        height: 420px;
      }
    }
  }
  .sec-parallax {
    height: 420px;
    width: 100%;
    .creation-bg {
      background-image: url("../../Images/creation_bg1.jpg");
      background-size: 100%;
    }
    .parallax-wrapper {
      position: relative;
      height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;

      .parallax-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 420px;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
    }
    .service-logo {
      text-align: center;
      position: relative;
      top: 195px;
    }
  }
  .plus {
    margin-top: 50px;
    position: relative;
    img::after {
      width: 30px;
      height: 5px;
      background-color: black;
      position: absolute;
      z-index: 1;
    }
    svg#heart {
      height: 110px;
      width: 110px;
      position: absolute;
      top: 0;
      left: 0;
    }
    #heart-path {
      fill: black;
      stroke: black;
      stroke-width: 3px;
      stroke-dasharray: 110;
      stroke-dashoffset: -110;
      stroke-linecap: butt;
      -webkit-transition: all 1s ease-out;
      -moz-transition: all 1s ease-out;
      -ms-transition: all 1s ease-out;
      -o-transition: all 1s ease-out;
      transition: all 1s ease-out;
    }

    #heart:hover #heart-path {
      fill: black;
      stroke-dashoffset: 1;
      stroke-dasharray: 110;
      stroke-width: 3px;
    }

    .hover-color:hover {
      color: #ea3335 !important;
      cursor: pointer;
    }
  }
}
#scene {
  width: 200px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  svg#heart {
    height: 85px;
    width: 85px;
    position: absolute;
    top: -101px;
    left: 0px !important;
  }
}
