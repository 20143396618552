.services-banner {
  position: relative;
  height: 100vh;
  background-image: url("../../Images/services_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .scrolling-text {
    font-weight: 400;
    font-size: 300px;
    line-height: 80%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #ffffff;
    white-space: nowrap;
    position: absolute;
    bottom: -25px;
    left: 200px;
    overflow: hidden;
    // transition: transform 0.25s;
    -webkit-transition: transform 0.28s ease-out;
    -moz-transition: transform 0.28s ease-out;
    -o-transition: transform 0.28s ease-out;
    -ms-transition: transform 0.28s ease-out;
    transition: transform 0.28s ease-out;
    // transition-delay: 0.1s;
    display: block !important;
    transform: translate(-1597.19px, 0px);
  }
}

// .services-banner {
//   position: relative;
//   background-image: url("../../Images/services_bg.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
//   overflow: hidden;
//   height: 300px;
// }

// .scrolling-text {
//     font-weight: 400;
//     font-size: 100px;
//     line-height: 80%;
//     letter-spacing: -0.025em;
//     text-transform: uppercase;
//     color: #ffffff;
//     white-space: nowrap;
//     position: absolute;
//     bottom: 0;
//     right: -200px;
//     float: right;
//     bottom: 0;
//   }
