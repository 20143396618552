.touch-circle {
  width: 190px;
  height: 190px;
  background: #eeeef0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner-div {
    width: 190px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    small {
      display: block;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #ea3335;
    }
    span {
      display: block;
      font-weight: 400;
      font-size: 36px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.025em;
      font-family: "Georgia", Sans-serif;
      text-transform: uppercase;
      color: #ea3335;
    }
  }
  &:hover {
    cursor: pointer;
    background: #000;
    .inner-div {
      -ms-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -o-transform: scale(1.25);
      transform: scale(1.25);
    }
  }
}
