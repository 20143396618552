@media (max-width: 900px) {
  .about-banner {
    .bg-sec {
      .react-parallax {
        .bg-img {
          height: 300px;
        }
      }
    }
  }
  .about-banner .parallax-wrapper {
    height: 300px;
    .parallax-bg {
      height: 300px;
    }
  }
  .about-banner .heading {
    font-size: 50px;
  }
  .about-info {
    padding-bottom: 150px;
  }
  .transform-sec {
    padding-bottom: 100px;
    .right-flex {
      display: block;
      justify-content: flex-end;
      margin-right: 0px;
      .title {
        font-size: 50px;
        text-align: left;
        margin-bottom: 20px;
        height: unset;
        .t{
          position: unset;
        }
      }
      .horizontal-parallax {
        width: 252.02px;
        height: 300px;
      }
    }
    .left-flex {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      margin-right: 0px;
      margin-top: 20px;
      .des {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .horizontal-parallax {
        width: 455px;
        height: 299.6px;
      }
    }
  }
  .accelerate-sec{
    margin-top: -2px;
  }
  .accelerate-sec .title {
    margin-left: 0px;
    font-size: 50px;
  }
  .accelerate-sec .des {
    margin-left: 0px;
    width: 100%;
    margin-bottom: 0;
  }
  .accelerate-sec .make-flex {
    margin-top: 50px;
    top: 0;
  }
  .sustain-sec{
    margin-top: -2px;
  }
  .sustain-sec .make-flex .title {
    font-size: 50px;
  }
  .team-members-sec {
    background-color: #fff;
    padding: 100px 0;
  }
  .team-members-sec .title {
    font-size: 50px;
  }
  .team-members-sec .des {
    width: 100%;
    margin-left: 0px;
  }
  .team-members-sec {
    .small-dot {
      margin-top: 24px;
    }
  }
}
@media (max-width: 700px) {
  .about-banner {
    padding-top: 200px;
    .heading {
      font-size: 40px;
      margin-bottom: 10px;
    }
    .bg-sec {
      height: 160.2px;
      display: block !important;
      .m-view {
        display: block !important;
        img {
          height: 258px !important;
        }
      }
      .about-banner-bg {
        background-position-x: 7% !important;
        height: 160.2px;
      }
      .react-parallax {
        .bg-img {
          height: 160.2px;
        }
      }
    }
    .parallax-wrapper {
      height: 250px;
      display: none;
    }
  }
  .about-info {
    padding-top: 50px;
    padding-bottom: 50px;
    .make-flex {
      display: block;
      justify-content: unset;
      .heading {
        width: 100%;
        font-size: 24px;
        margin-right: 0px;
        margin-bottom: 20px;
      }
      .description {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
  .transform-sec {
    padding-bottom: 50px;
    .right-flex {
      display: block;
      justify-content: flex-end;
      margin-right: 0px;
      .title {
        font-size: 40px;
        text-align: left;
        margin-bottom: 20px;
      }
      .horizontal-parallax {
        width: 152.02px;
        height: 200px;
      }
    }
    .left-flex {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      margin-right: 0px;
      margin-top: 20px;
      .des {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .horizontal-parallax {
        width: 305px;
        height: 199.6px;
      }
    }
  }
  .accelerate-sec {
    padding-bottom: 50px;
    .title {
      margin-left: 0px;
      font-size: 40px;
      margin-bottom: 20px;
    }
    .des {
      margin-left: 0px;
      width: 100%;
    }
    .make-flex {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: relative;
      z-index: 0;
      top: 0;
      .parallax-neck {
        height: 243px;
        &::before {
          content: "";
          background: #03081b;
          width: 111.7px;
          height: 75.22px;
          position: absolute;
          top: 0;
          left: 0;
        }
        &::after {
          content: "";
          background: #03081b;
          width: 194px;
          height: 115px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .block-v {
          background: #03081b;
          position: absolute;
          width: 4.56px;
          height: 243px;
          left: 111.7px;
          top: 0;
          z-index: 1;
        }
        .parallex-bg1 {
          width: 310.02px;
          height: 243px;
        }
        .neck {
          width: 310.02px;
          height: 243px;
          background-image: url("../../Images/main_face.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    // .make-flex {
    //   .neck {
    //     width: 111.7px;
    //     height: 167.77px;
    //     margin-top: 100px;
    //     margin-right: 5px;
    //   }
    //   .face {
    //     width: 193.76px;
    //     height: 127.2px;
    //     margin-top: 26px;
    //   }
    // }
  }
  .sustain-sec {
    padding-bottom: 80px;
    .make-flex {
      display: block;
      justify-content: flex-start;
      .title {
        font-size: 40px;
        text-align: left;
        margin-right: 0px;
        margin-bottom: 20px;
      }
      .des {
        width: 100%;
      }
    }
  }
  .team-members-sec {
    padding: 80px 0 160px;
    .title {
      font-size: 40px;
      margin-bottom: 20px;
    }
    .des {
      width: 100%;
      margin-left: 0px;
    }
    .team-wrapper {
      margin-top: 70px;
      margin-left: 0 !important;
      .team-box {
        // width: 410px;
        // height: 496px;
      }
    }
    .small-dot {
      margin-top: 21px;
      margin-left: 10px;
      width: 14px;
      height: 14px;
    }
  }
}

@media (max-width: 480px) {
  .team-members-sec {
    .team-wrapper {
      .team-box {
        margin: 0 5px;
      }
    }
  }

  .js-magnetic-area {
    display: none;
  }
}
