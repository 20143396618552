@media (max-width: 2000px) {
  .footer {
    .inner-widgets {
      .info {
        .heading {
          margin-bottom: 16px;
        }
        .item {
          margin-bottom: 16px;
        }
      }
    }
    .on-page {
      margin-top: 250px;
      font-weight: 400;
      font-size: 100px;
    }
    .bottom-menu {
      padding: 0 40px;
      margin-top: 80px;
    }
  }
}
@media (max-width: 1800px) {
  .footer {
    .on-page {
      margin-top: 260px;
      font-weight: 400;
      font-size: 100px;
    }
    .bottom-menu {
      padding: 0 40px;
      margin-top: 100px;
    }
  }
}
@media (max-width: 1700px) {
  .footer {
    .on-page {
      margin-top: 200px;
      font-weight: 400;
    }
    .bottom-menu {
      padding: 0 40px;
      margin-top: 80px;
    }
  }
}
@media (max-width: 1550px) {
  .footer {
    .inner-widgets {
      .info {
        .heading {
          margin-bottom: 22px;
        }
        .item {
          margin-bottom: 22px;
        }
      }
    }
    .on-page {
      margin-top: 200px;
      font-weight: 400;
      font-size: 90px;
    }
    .bottom-menu {
      padding: 0 40px;
      margin-top: 60px;
    }
  }
}
@media (max-width: 1440px) {
  .footer {
    .inner-widgets {
      margin-top: 5rem;

      // .container {
      //   max-width: 1290px;
      // }

      .info {
        .heading {
          // margin-bottom: 10px;
          // font-size: 20px;
          font-size: 30px;
          // font-weight: 400;
          margin-bottom: 12px;
        }
        .item {
          // margin-bottom: 10px;

          .head {
            font-size: 17px;
          }

          .des {
            font-size: 16px;
          }

          .link {
            font-size: 16px;
          }
        }

        .descripton {
          // font-size: 12px;
          font-size: 16px;
          // line-height: 25px;
        }
      }
    }

    .on-page {
      // .container {
      //   max-width: 1330px;
      // }
      // margin-top: 48px;
      // font-weight: 400;
      // font-size: 85px;
      bottom: 80px;
      // margin-right: 60px !important;
    }

    // .bottom-menu {
    //   // padding: 0 40px;
    //   // margin-top: 40px;
    // }

    // .top-menu {
    //   .footer-menu {
    //     ul {
    //       li {
    //         // padding-top: 22px;
    //         // padding-bottom: 22px;
    //         // font-size: 13px;
    //       }
    //     }
    //   }
    // }
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1366px) {
  .footer {
    .inner-widgets {
      margin-top: 5rem;

      .info {
        .heading {
           &::before {
            top: 15px;
          }
        }
      }

      .container {
        max-width: 1290px;
      }
    }
  }

  // .on-page {
  //   .container {
  //     max-width: 1330px !important;
  //   }
  // }
}

@media only screen and (max-width: 1390px) and (min-width: 1366px) {
  .footer {
    .inner-widgets {
      .container {
        max-width: 1230px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .footer {
    height: 100vh;

    .inner-widgets {
      // .container {
      //   max-width: auto;
      // }

      margin-top: 40px;
      .info {
        .heading {
          margin-bottom: 10px;
          font-size: 20px;
        }
        .item {
          margin-bottom: 10px;

          .head {
            font-size: 13px;
          }

          .des {
            font-size: 12px;
          }

          .link {
            font-size: 12px;
          }
        }

        .descripton {
          font-size: 12px;
        }
      }
    }

    .on-page {
      // .container {
      //   max-width: auto;
      // }

      margin-top: 48px;
      font-weight: 400;
      font-size: 70px;
      bottom: 73px;
      padding: 0 30px;
      // margin-right: 60px !important;
    }

    .bottom-menu {
      padding: 0 40px;
      margin-top: 40px;
    }

    .top-menu {
      .footer-menu {
        ul {
          li {
            padding-top: 22px;
            padding-bottom: 22px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .footer {
    .inner-widgets {
      .container {
        padding-left: 72px;
      }
    }
  }
}

@media (max-width: 1250px) {
  .footer {
    height: 100vh;
    .inner-widgets {
      .container {
        max-width: 1150px;
      }
      .info {
        .heading {
          margin-bottom: 16px;
        }
        .item {
          margin-bottom: 16px;
        }
      }
    }
    .on-page {
      margin-top: 48px;
      font-weight: 400;
      font-size: 70px;
      bottom: 0px;
      position: relative;
    }
    .bottom-menu {
      position: relative;
      padding: 0 40px;
      margin-top: 20px;
    }
  }
}


@media (max-width: 980px) {
  .footer {
    .inner-widgets {
      .container {
        padding-left: 30px;
      }
    }
    .on-page {
      padding: 0px;
    }
  }
}

@media (max-width: 900px) {
  #mytext {
    display: none;
  }
  .footer {
    height: unset;
  }
  .footer .inner-widgets .info {
    padding-left: 30px;
    margin-bottom: 80px;
  }
  .home-banner .heading {
    font-size: 50px;
  }
  .home-banner {
    .bg-sec {
      .react-parallax {
        .bg-img {
          height: 275px;
        }
      }
    }
  }
  .home-banner .parallax-wrapper {
    height: 275px;
  }
  .home-banner .parallax-bg {
    height: 275px;
  }
  .make-scaling {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .make-scaling .info .head {
    font-size: 32px;
  }
  .make-scaling .get-in .touch-circle {
    margin: 50px auto;
  }
  .creation-home {
    .d-flex {
      display: flex;
      .text {
        .sec-title {
          font-size: 60px;
          padding: 20px 20px 20px;
          margin: 0;
          text-align: left;
        }
      }
    }
  }
  .creation-home .align-basline {
    padding: 0 0;
  }
  .creation-home {
    .text {
      height: unset;
    }
    .sec-title {
      position: unset;
      left: unset;
      right: unset;
    }
  }
  .augmentation {
    .plus {
      svg#heart {
        left: 0 !important;
        right: unset;
      }
    }
    .text {
      height: unset;
    }
    .sec-title {
      position: unset;
      left: unset;
      right: unset;
    }
  }
  .creation-home .plus {
    text-align: left !important;
  }
  .creation-home .bg-sec {
    transition: transform 0.2s;
    .react-parallax {
      .bg-img {
        height: 300px;
      }
    }
  }
}
@media (max-width: 700px) {
  #mytext {
    display: none;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
  }
  .header {
    padding: 0;
  }
  .magnetic-wrap:nth-child(1) .magnetic-size {
    width: 0px;
    left: -81px;
  }
  .header .header-menu ul li {
    padding-left: 9px;
    padding-right: 9px;
    font-size: 12px;
    &:last-child {
      padding-right: 0px;
    }
  }
  .footer {
    height: unset;
  }
  .footer .top-menu .footer-menu ul li {
    padding-left: 9px;
    padding-right: 9px;
    font-size: 12px;
    &:last-child {
      padding-right: 0px;
    }
  }
  .footer .top-menu {
    padding: 0;
  }
  .footer .inner-widgets {
    margin-top: 80px;
  }
  .footer .inner-widgets .info .heading {
    font-size: 18px;
  }
  .footer .inner-widgets .info .heading::before {
    width: 10px;
    height: 10px;
    left: -24px;
    top: 6.8px;
  }
  .footer .inner-widgets .info {
    padding-left: 30px;
    margin-bottom: 60px;
  }
  .footer .inner-widgets .info .descripton {
    padding-right: 0;
  }
  .footer .inner-widgets .info .offices-list .item {
    br {
      display: none;
    }
  }
  .footer .inner-widgets .on-page {
    margin-top: 80px;
    font-size: 80px;
  }
  .footer .on-page {
    font-size: 50px;
    margin-top: 20px;
  }
  .footer .bottom-menu .footer-menu ul {
    margin: 0 auto;
    justify-content: start;
  }
  .footer .bottom-menu .footer-menu ul li {
    padding-left: 0;
    padding-right: 20px;
    padding-top: 22px;
    padding-bottom: 20px;
  }
  .footer .bottom-menu .copy-rights {
    padding-top: 20px;
    padding-bottom: 0px;
    font-size: 16px;
  }
  .footer .bottom-menu {
    padding: 0 30px;
    margin-top: 72px;
  }
  .home-banner {
    padding-top: 160px;
    position: relative;
  }
  .home-banner .heading {
    font-size: 40px;
    margin-bottom: 16px;
  }
  .home-banner {
    .bg-sec {
      height: 137px;
      .m-view {
        display: block;
      }
      .react-parallax {
        img {
          height: 275px !important;
        }
        .bg-img {
          height: 137px;
        }
      }
    }
  }
  .home-banner .parallax-wrapper {
    height: 137px;
    display: none;
  }
  .home-banner {
    .main-banner-bg {
      background-image: url("../../Images/home_mobile_bg.jpg");
    }
  }
  .home-banner .parallax-bg {
    height: 137px;
  }
  .make-scaling {
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .make-scaling .info .head {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 20px;
  }
  .make-scaling .info {
    padding-right: 0;
  }
  .make-scaling .info .des {
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .creation-home {
    .d-flex {
      display: block !important;
      .text {
        .sec-title {
          font-size: 40px;
          text-align: left;
          padding: 20px 0 20px;
          margin: 0;
        }
      }
      .px-img {
        width: 100% !important;
      }
    }
  }
  .creation-home .align-basline {
    align-self: unset;
    padding: 0;
  }
  .creation-home .sec-parallax {
    height: 260.4px;
  }
  .creation-home .sec-parallax .parallax-wrapper {
    height: 260.4px;
  }
  .creation-home .sec-parallax .parallax-wrapper .parallax-bg {
    height: 260.4px;
  }
  .creation-home {
    padding-bottom: 80px;
  }
  .creation-home .plus {
    margin-top: 14px;
    width: 100% !important;
    text-align: left !important;
    img {
      width: 60px !important;
      height: 60px !important;
    }
    #heart {
      width: 60px !important;
      height: 60px !important;
    }
  }
  .creation-home .bg-sec {
    transition: transform 0.2s;
    .react-parallax {
      .bg-img {
        height: 260.4px;
      }
    }
  }

  .footer {
    .bottom-menu {
      .container {
        .row {
          flex-direction: column-reverse;
          .copy-rights {
            padding-top: 0;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
