.team-wrapper {
  width: 100%;
  background: #ffffff;
  position: relative;
  align-items: center;
  overflow: hidden;
  margin-top: 100px;
  transition: 0.2s;
  // margin-left: 32px;
  cursor: grab;
  // margin-left: 20px;
}

.team-box {
  // width: 410px;
  // height: 496px;
  // min-height: 471px;
  background-color: #000000;
  margin: 0 1.4rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // transition: 0.7s;
  cursor: grab;
}

.member-name,
.member-designation {
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 35px;
  font-weight: 700;
  pointer-events: none;
}

.member-designation {
  font-size: 12px;
}

.team-box.active {
  color: white;
  border: 2px solid white;
}

.rubber-effect {
  animation: rubberband 2s linear infinite;
  animation-duration: 2s;
}

@keyframes rubberband {
  0% {
    transform: scaleX(1);
  }
  40% {
    transform: scaleX(1.12) scaleY(0.75);
  }
  55% {
    transform: scaleX(0.85) scaleY(1);
  }
  65% {
    transform: scaleX(1.09) scaleY(0.85);
  }
  75% {
    transform: scaleX(0.9) scaleY(1);
  }
  90% {
    transform: scaleX(1.05) scaleY(0.95);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

/* Shine */
.team-inner {
  position: relative;
}
.team-inner::before {
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.team-inner:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

// .team-inner:hover::before {
// 	-webkit-animation: shine .75s;
// 	animation: shine .75s;
// }
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

// TEam box animation
.team-box .team-inner {
  position: relative;
  overflow: hidden;

  .top-box {
    width: 300px;
    height: 500px;
    background-color: #b99163;
    opacity: 0.4;
    position: absolute;
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
    filter: blur(40px);
    -webkit-transition: 0.45s;
    transition: 0.45s;
  }

  .top-box-one {
    -webkit-transform: rotate(34deg);
    transform: rotate(34deg);
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    right: -350px;
    bottom: -350px;
    z-index: 10;
    pointer-events: none;
  }

  .top-box-two {
    -webkit-transform: rotate(34deg);
    transform: rotate(34deg);
    left: -350px;
    top: -350px;
    z-index: 10;
    pointer-events: none;
  }

  &:hover {
    .top-box-one {
      right: -200px;
      bottom: -200px;
    }

    .top-box-two {
      left: -200px;
      top: -200px;
    }
  }
}

.team-mobile-wrapper {
  padding-left: 5px;

  .slick-slide {
    width: 306px !important;
  }

  .slick-track {
    -webkit-transition: 0.25s linear !important;
    -moz-transition: 0.25s linear !important;
    -o-transition: 0.25s linear !important;
    transition: 0.25s linear !important;

    scroll-behavior: smooth !important;
  }
}

@media (max-width: 520px) {
  #main-team-section {
    margin-left: 10px;
  }
}

.mobile-view-team-main {
  display: flex;
}

.mobile-team {
  flex: 0 0 auto;
  width: 300px;
}
