/* dm-sans-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./Images/fonts/dm-sans-v11-latin-regular.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-regular.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-regular.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-regular.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-regular.svg#DMSans') format('svg');
    /* Legacy iOS */
}

/* dm-sans-italic - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./Images/fonts/dm-sans-v11-latin-italic.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-italic.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-italic.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-italic.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-italic.svg#DMSans') format('svg');
    /* Legacy iOS */
}

/* dm-sans-500 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./Images/fonts/dm-sans-v11-latin-500.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-500.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-500.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-500.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-500.svg#DMSans') format('svg');
    /* Legacy iOS */
}

/* dm-sans-500italic - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 500;
    src: url('./Images/fonts/dm-sans-v11-latin-500italic.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-500italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-500italic.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-500italic.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-500italic.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-500italic.svg#DMSans') format('svg');
    /* Legacy iOS */
}

/* dm-sans-700 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./Images/fonts/dm-sans-v11-latin-700.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-700.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-700.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-700.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-700.svg#DMSans') format('svg');
    /* Legacy iOS */
}

/* dm-sans-700italic - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    src: url('./Images/fonts/dm-sans-v11-latin-700italic.eot');
    /* IE9 Compat Modes */
    src: url('./Images/fonts/dm-sans-v11-latin-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./Images/fonts/dm-sans-v11-latin-700italic.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('./Images/fonts/dm-sans-v11-latin-700italic.woff') format('woff'),
        /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
        url('./Images/fonts/dm-sans-v11-latin-700italic.ttf') format('truetype'),
        /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
        url('./Images/fonts/dm-sans-v11-latin-700italic.svg#DMSans') format('svg');
    /* Legacy iOS */
}