.services-info {
  padding-top: 200px;
  position: relative;
  overflow: hidden;
  .item {
    margin-bottom: 95px;
    position: relative;
    z-index: 1;
    &:last-child {
      margin-bottom: 200px;
    }
    .head {
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 20px;
      span {
        text-transform: lowercase;
        font-family: "Georgia";
      }
      label {
        font-family: "Georgia";
        margin-bottom: 0;
      }
    }
    .des {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #000000;
    }
  }
}
.my_circle {
  position: absolute;
  -webkit-animation: moveX 4.5s linear 0s infinite alternate,
    moveY 4.5s linear 0s infinite alternate;
  -moz-animation: moveX 4.5s linear 0s infinite alternate,
    moveY 4.5s linear 0s infinite alternate;
  -o-animation: moveX 4.5s linear 0s infinite alternate,
    moveY 4.5s linear 0s infinite alternate;
  animation: moveX 3.05s linear 0s infinite alternate,
    moveY 4.5s linear 0s infinite alternate;
}

.my_circle2 {
  position: absolute;
  -webkit-animation: moveX 4.05s linear 0s infinite alternate,
    moveY 4.4s linear 0s infinite alternate;
  -moz-animation: moveX 4.05s linear 0s infinite alternate,
    moveY 3.4s linear 0s infinite alternate;
  -o-animation: moveX 4.05s linear 0s infinite alternate,
    moveY 4.4s linear 0s infinite alternate;
  animation: moveX 4.05s linear 0s infinite alternate,
    moveY 4.4s linear 0s infinite alternate;
}

.my_circle3 {
  position: absolute;
  -webkit-animation: moveX 3.5s linear 0s infinite alternate,
    moveY 3.5s linear 0s infinite alternate;
  -moz-animation: moveX 3.5s linear 0s infinite alternate,
    moveY 3.5s linear 0s infinite alternate;
  -o-animation: moveX 3.5s linear 0s infinite alternate,
    moveY 3.5s linear 0s infinite alternate;
  animation: moveX 3.5s linear 0s infinite alternate,
    moveY 3.5s linear 0s infinite alternate;
}

@-webkit-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 500px;
  }
}
@-moz-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 0px;
  }
}
@-o-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 0px;
  }
}
@keyframes moveX {
  from {
    left: -30%;
  }
  to {
    left: 90%;
  }
}
@-webkit-keyframes moveY {
  from {
    top: 0;
  }
  to {
    top: -100px;
  }
}
@-moz-keyframes moveY {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
@-o-keyframes moveY {
  from {
    top: 0px;
  }
  to {
    top: 0;
  }
}
@keyframes moveY {
  from {
    top: 0%;
  }
  to {
    top: 80%;
  }
}
