.footer {
  position: relative;
  overflow: hidden;
  z-index: 100;
  height: 100vh;
  .sticky {
    position: sticky;
  }
  .top-menu {
    padding: 0 40px;
    .container {
      border-top: 1px solid rgba(3, 8, 27, 0.1);
      border-bottom: 1px solid rgba(3, 8, 27, 0.1);
    }
    .logo {
      display: inline;
    }
    .col-3 {
      align-self: center;
    }
    .footer-menu {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        li {
          list-style: none;
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.2em;
          color: #03081b;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 30px;
          padding-bottom: 30px;
          text-transform: uppercase;
          a {
            text-decoration: none;
            color: #03081b;
            -webkit-transition: 0.25s;
            transition: 0.25s;

            &:hover {
              color: #ea3335 !important;
            }
          }
        }
      }
    }
  }
  .inner-widgets {
    margin-top: 50px;
    .info {
      .heading {
        position: relative;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 22px;
        &::before {
          content: "";
          width: 14px;
          height: 14px;
          background-color: #000000;
          border-radius: 30px;
          position: absolute;
          left: -30px;
          top: 8.8px;
        }
      }
      .descripton {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.025em;
        color: #000000;
        padding-right: 200px;
      }
      .item {
        margin-bottom: 22px;
        .head {
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.025em;
          text-transform: uppercase;
          color: #ea3335;
        }
        .des {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: -0.025em;
          text-transform: uppercase;
          color: #000000;
          a {
            color: #000000;
            text-decoration: none;
            &:hover {
              color: #ea3335;
            }
          }
        }
        .link {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.025em;
          text-transform: uppercase;
          color: #000000;
          text-decoration: underline;
          a {
            color: #000000;
            &:hover {
              color: #ea3335;
            }
          }
        }
      }
    }
  }
  .on-page {
    margin-top: 130px;
    font-weight: 400;
    font-size: 107px;
    line-height: 73%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    text-align: right;
    font-family: "Georgia", Sans-serif;
    position: absolute;
    bottom: 120px;
    right: 0;
    width: 100%;
    .container {
      a {
        font-family: "Georgia", Sans-serif;
        text-decoration: none;
        color: #000000;

        &:hover {
          color: #ea3335;
        }
      }
    }
  }
  .bottom-menu {
    padding: 0 40px;
    margin-top: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    .container {
      border-top: 1px solid rgba(3, 8, 27, 0.1);
    }
    .copy-rights {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #000000;
      padding-top: 20px;
      padding-bottom: 20px;
      a {
        text-decoration: none;
        color: #03081b;
        &:hover {
          color: #ea3335;
        }
      }
    }
    .col-3,
    .col-9 {
      align-self: center;
    }
    .footer-menu {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        li {
          list-style: none;
          font-weight: 700;
          font-size: 12px;
          line-height: 140%;
          letter-spacing: 0.2em;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 20px;
          padding-bottom: 20px;
          text-transform: uppercase;
          a {
            text-decoration: none;
            color: #03081b;
            -webkit-transition: 0.25s;
            transition: 0.25s;

            &:hover {
              color: #ea3335 !important;
            }
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
    color: #03081b;
    -webkit-transition: 0.25s;
    transition: 0.25s;

    &:hover {
      color: #ea3335 !important;
    }
  }
}

#ball-box-wrapper {
  position: relative !important;
  overflow: hidden;
  background-color: #ffffff;
}

.mouse-circle {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 10px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #ea3335;
}

#ball {
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  z-index: 50;
  background-color: #ea3335;
  left: 62px;
  top: -70px;
  // -webkit-transition: 0.3s;
  // transition: 0.3s;
}

@keyframes bouncing {
  0% {
    transform: translateY(600);
  }
  50% {
    transform: translateY(600);
  }
  100% {
    transform: translateY(100vh);
  }
}

