.mar-top {
  margin-top: 190px;
  .page-heading {
    font-weight: 400;
    font-size: 135px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    span {
      text-transform: lowercase;
      font-family: Georgia;
    }
  }
  .make-flex {
    display: flex;
    width: 100%;
    .des {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #292930;
      width: 377px;
      margin-right: 85px;
      margin-top: 20px;
      .lab {
        margin-bottom: 20px;
      }
      .listing {
        .item {
          position: relative;
          left: 14px;
          &::before {
            content: "";
            width: 4px;
            height: 4px;
            background-color: #292930;
            border-radius: 10px;
            position: absolute;
            top: 7px;
            left: -14px;
          }
        }
      }
    }
    .parallax_bg {
      width: 588px;
      height: 498px;
      // background-image: url("../../Images/creation_bg.jpg");
      // background-repeat: no-repeat;
      // background-size: cover;
      // text-align: center;
      transition: transform 0.2s;
      margin-top: -90px;
      // img {
      //   margin-top: 225px;
      // }
      .react-parallax {
        transition: transform 0.2s;
        .react-parallax-bgimage {
          transition: transform 0.2s;
        }
        .bg-img {
          height: 498px;
        }
      }
    }
  }
  .info {
    margin-top: 83px;
    margin-bottom: 200px;
    .head {
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 26px;
      span {
        text-transform: lowercase;
        font-family: Georgia;
      }
      label {
        margin-bottom: 0;
        font-family: Georgia;
      }
    }
    .des {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #292930;
      padding-right: 150px;
    }
  }
}
