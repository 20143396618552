.background-dark {
  .header {
    .container {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .logo {
      path {
        fill: #fff;
      }
    }
    .header-menu {
      ul {
        li {
          color: #fff;
          a {
            color: #fff;
            &:hover {
              color: #ea3335;
            }
          }
        }
      }
    }
  }
}
.transform-sec {
  padding-bottom: 100px;
  .right-flex {
    display: flex;
    justify-content: flex-end;
    margin-right: 160px;
    .title {
      font-weight: 400;
      font-size: 70px;
      line-height: 100%;
      text-align: right;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #ffffff;
      margin-right: 30px;
      align-self: last baseline;
      height: 421px;
      position: relative;
      font-family: "Georgia";
      .t {
        font-family: "Georgia";
        position: absolute;
        right: 0;
        bottom: 0;
      }
      span {
        text-transform: lowercase;
        font-family: "Georgia";
      }
    }
    .parallax-left {
      position: relative;
      height: 421px;
      display: flex;
      justify-content: center;
      align-items: center;
      .parallex-bg1 {
        position: absolute;
        top: 0;
        left: 0;
        height: 421px;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
    }
    .horizontal-parallax {
      width: 320px;
      height: 421px;
      background-image: url("../../Images/TRANSFORM1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .left-flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .parallax-right {
      position: relative;
      height: 445px;
      display: flex;
      justify-content: center;
      align-items: center;
      .parallex-bg1 {
        position: absolute;
        top: 0;
        left: 0;
        height: 445px;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
    }
    .horizontal-parallax {
      width: 680px;
      height: 445px;
      background-image: url("../../Images/TRANSFORM2.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);
    }
    .des {
      width: 320px;
      margin-left: 40px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #ffffff;
    }
  }
}
.accelerate-sec {
  padding-bottom: 100px;
  background: rgb(3, 8, 27);
  margin-bottom: -1px;
  .title {
    margin-left: 160px;
    font-family: "Georgia";
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 26px;
    span {
      font-family: "Georgia";
      text-transform: lowercase;
    }
  }
  .des {
    margin-left: 160px;
    width: 380px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.025em;
    color: #ffffff;
    position: relative;
    z-index: 2;
    margin-bottom: -34px;
  }
  .make-flex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    z-index: 0;
    top: -40px;
    .parallax-neck {
      position: relative;
      height: 524px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        content: "";
        background: #03081b;
        width: 255px;
        height: 156px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &::after {
        content: "";
        background: #03081b;
        width: 435px;
        height: 245px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      .parallex-bg1 {
        position: absolute;
        top: 0;
        left: 0;
        height: 524px;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
      .block-v {
        background: #03081b;
        position: absolute;
        width: 10px;
        height: 524px;
        left: 245px;
        top: 0;
        z-index: 1;
      }
    }
    .neck {
      width: 680px;
      height: 524px;
      background-image: url("../../Images/main_face.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);
      // margin-top: 114px;
      // margin-right: 10px;
    }
    .parallax-face {
      position: relative;
      height: 279px;
      display: flex;
      justify-content: center;
      align-items: center;
      .parallex-bg1 {
        position: absolute;
        top: 0;
        left: 0;
        height: 279px;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }
    }
    .face {
      width: 425px;
      height: 279px;
      background-image: url("../../Images/face.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: -38px;
    }
  }
}
.sustain-sec {
  padding-bottom: 100px;
  background: rgb(3, 8, 27);
  .make-flex {
    display: flex;
    justify-content: flex-start;
    .title {
      font-family: "Georgia";
      font-weight: 400;
      font-size: 70px;
      line-height: 100%;
      text-align: right;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #ffffff;
      margin-right: 74px;
      span {
        font-family: "Georgia";
        text-transform: lowercase;
      }
    }
    .des {
      width: 320px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #ffffff;
      margin-top: 6px;
    }
  }
}

.sprite-container {
  width: 300px;
  height: 200px;
  display: inline-block;
  background-image: url("../../Images/face.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  overflow-x: visible;
  overflow: visible;
}
