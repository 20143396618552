.about-banner {
  padding-top: 175px;
  position: relative;
  background: rgb(3, 8, 27);
  margin-bottom: -1px;
  .heading {
    font-weight: 400;
    font-size: 80px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 10px;
    label {
      font-family: "Georgia", Sans-serif;
      margin-bottom: 0;
      span {
        font-family: "Georgia", Sans-serif;
        text-transform: lowercase;
      }
    }
  }

  .bg-sec {
    transition: transform 0.2s;
    height: 537px;
    position: relative;
    overflow: hidden;
    .react-parallax {
      transition: transform 0.2s;
      .react-parallax-bgimage {
        transition: transform 0.2s;
      }
      .bg-img {
        height: 537px;
      }
    }
    .about-banner-bg {
      width: 100%;
      height: 440px;
      background-image: url("../../Images/about-banner1.jpg");
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
      // margin-top: -100px;
    }
  }

  .m-view{
    display: none;
  }

  .bg-sec {
    transition: transform 0.2s;
    display: none;
    .react-parallax {
      transition: transform 0.2s;
      .react-parallax-bgimage {
        transition: transform 0.2s;
      }
      .bg-img {
        height: 537px;
      }
    }
  }
  .parallax-wrapper {
    position: relative;
    height: 458px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .parallex-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 458px;
    width: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .about-main-bg {
    width: 100%;
    height: 458px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
  }
  .arrow-bottom {
    position: absolute;
    top: 330px;
    right: 42px;
  }
}
.about-info {
  padding-top: 108px;
  padding-bottom: 200px;
  background: rgb(3, 8, 27);
  margin-bottom: -1px;
  .make-flex {
    display: flex;
    justify-content: flex-end;
    .heading {
      width: 479px;
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #ffffff;
      margin-right: 60px;
      label {
        font-family: "Georgia", Sans-serif;
        margin-bottom: 0;
        display: unset;
      }
      span {
        font-family: "Georgia", Sans-serif;
        text-transform: lowercase;
      }
    }
    .description {
      width: 295px;
      margin-right: 20px;
      .item {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.025em;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
}

.about-main-bg {
  background-image: url("../../Images/about-banner1.jpg");
}
