.header {
  padding: 0 40px;
  width: 100%;
  position: fixed;
  top: 0;
  transition: 0s;
  left: 0;
  z-index: 1;
  -webkit-transition: 1s;
  transition: 0.3s;
  .container {
    border-bottom: 1px solid rgba(3, 8, 27, 0.1);
  }
  .logo {
    display: inline;
  }
  .col-3 {
    align-self: center;
  }
  .header-menu {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      li {
        list-style: none;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.2em;
        color: #03081b;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 30px;
        padding-bottom: 30px;
        text-transform: uppercase;
        a {
          text-decoration: none;
          color: #03081b;
          -webkit-transition: 0.25s;
          transition: 0.25s;

          &:hover {
            color: #ea3335 !important;
          }
        }
      }
    }
  }
}
.hidden {
  /* display: none; */
  top: -200px;
}
.circle-bounce {
  animation: bounce 2s linear infinite;
  animation-duration: 2s;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-50px);
  }
  60% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-50px);
  }
  60% {
    transform: translateY(-10px);
  }
}

#home-page-wrapper {
  width: 100%;
  display: inline-block;
  // background: #F9F9F9;
}

.target,
.cursor {
  border-radius: 50px;
  width: 0px;
  height: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  left: 0;
  // -webkit-transform: translate(-50%, -50%) rotate(0deg);
  // transform: translate(-50%, -50%) rotate(0deg);
}

/* Green Tri Small */
.target {
  border-radius: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 30px;
}

.magnetic-wrap {
  display: inline-block;
  position: relative;

  &:nth-child(1) {
    .magnetic-size {
      width: 165px;
      left: -81px;
    }
  }

  &:nth-child(2) {
    .magnetic-size {
      width: 110px;
      left: -7px;
    }
  }

  &:nth-child(3) {
    .magnetic-size {
      width: 127px;
      left: -5px;
    }
  }
}

.magnetic-size {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  /* cursor: none; */
  height: 173px;
  z-index: 0;
}
