@media (max-width: 900px) {
  .faq-image {
    width: 500px;
    height: 400px;
  }
  .faqs-banner .heading {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 700px) {
  .faqs-banner {
    margin-top: 200px;
    .faq-image {
      width: 100%;
      height: 132px;
      float: right;
      // margin-bottom: -46px;
      background-size: 229.44px;
    }
    .eye-image {
      width: 73.5%;
      height: 132px;
      margin-bottom: 0px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      float: right;
    }
    .heading {
      margin-left: 0;
      img {
        margin-left: 0px;
        width: 100%;
        margin-top: -6.4vw;
      }
    }
  }
  .faqs-sec {
    margin-top: 100px;
    margin-bottom: 150px;
    .content .panel-group .panel .panel-heading h4 a {
      font-size: 18px;
      width: 95%;
      .faq-arrow {
        right: 0px;
        top: 0;
      }
    }
    .accordion {
      margin-top: 100px;
      .accordion-item {
        border-bottom: 0.5px solid #0f1426;
        .accordion-header {
          margin-bottom: 0;
          .accordion-button {
            font-size: 18px;
            .arrow {
              position: absolute;
              right: 0px;
              top: 12px;
            }
          }
        }
      }
    }
  }
}
