@import url("font.css");

body {
  margin: 0;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #F9F9F9;
}

* {
  font-family: 'DM Sans';
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1065px;
  }
  .header,
  .footer {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1330px;
    }
    .inner-widgets,
    .on-page {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl {
        max-width: 1310px;
      }
    }
  }
}
