@media (max-width: 900px) {
  // .services-banner {
  //   height: 700px;
  // }
  // .scroll-page {
  //   height: 100vh !important;
  // }
  .services-banner .scrolling-text {
    font-size: 180px;
    left: 30px;
  }
  .services-info {
    padding-top: 100px;
  }
}
@media (max-width: 700px) {
  .services-banner {
    // height: 100vh;
    // .scroll-page {
    //   height: 100vh !important;
    // }
    .scrolling-text {
      font-weight: 400;
      font-size: 110px;
      line-height: 80%;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #ffffff;
      white-space: nowrap;
      position: absolute;
      bottom: -10px;
      left: 32px;
      overflow: hidden;
    }
  }
  .services-info {
    padding-top: 100px;
    .item {
      margin-bottom: 50px;
      .head {
        font-size: 24px;
      }
    }
  }
}
