.home-banner {
  padding-top: 182px;
  position: relative;
  background: #f9f9f9;
  .heading {
    font-weight: 400;
    font-size: 70px;
    line-height: 100%;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 20px;
    label {
      font-family: "Georgia", Sans-serif;
      margin-bottom: 0;
      span {
        font-family: "Georgia", Sans-serif;
        text-transform: lowercase;
      }
    }
  }
  .bg-sec {
    transition: transform 0.2s;
    height: 440px;
    position: relative;
    overflow: hidden;
    .react-parallax {
      transition: transform 0.2s;
      .react-parallax-bgimage {
        transition: transform 0.2s;
      }
      .bg-img {
        height: 458px;
      }
    }
    .m-view {
      display: none;
    }
    .main-banner-bg {
      width: 100%;
      height: 440px;
      background-image: url("../../Images/home-banner1.jpg");
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
      // margin-top: -100px;
    }
  }
  .parallax-wrapper {
    position: relative;
    height: 458px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 458px;
    width: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .parallex-bg {
    width: 100%;
    height: 458px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
  }
  .arrow-bottom {
    position: absolute;
    top: 330px;
    right: 42px;
  }
}
.make-scaling {
  padding-top: 94px;
  padding-bottom: 220px;
  background: #f9f9f9;
  margin-top: -1px;
  .info {
    padding-right: 40px;
    .head {
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 20px;
      label {
        font-family: "Georgia", Sans-serif;
        margin-bottom: 0;
      }
      span {
        font-family: "Georgia", Sans-serif;
        text-transform: lowercase;
      }
    }
    .des {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.025em;
      color: #292930;
      padding-right: 80px;
    }
  }
  a {
    text-decoration: none;
    display: contents;
  }
  .get-in {
    .touch-circle {
      margin: 0 auto;
    }
  }
}
.parallax-container {
  height: 100vh;
  overflow: hidden;
}

.parallax-image {
  height: 150%;
  transform: scaleY(-1);
}
