.argmentation-bg {
  background-image: url("../../Images/augmentation1.jpg");
}
.augmentation{
  .plus{
    svg#heart{
      left: unset !important;
      right: 0;
    }
  }
  .sec-title{
    left: 0;
    right: unset;
  }
}